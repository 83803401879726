import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ExportToCsv} from 'export-to-csv';
import {DatePipe} from '@angular/common';

const CITY_OPTIONS = [
  'Aberdeen',
  'Aberystwyth',
  'Aldershot',
  'Aylesbury',
  'Ayr',
  'Ballymena',
  'Bangor',
  'Barnstapl',
  'Barrow',
  'Basildon',
  'Belfast',
  'Berwick',
  'Birmingham',
  'Blackpool',
  'Bolton',
  'Boston',
  'Bournemouth',
  'Bradford',
  'Bridgend',
  'Brighton',
  'Bristol',
  'Builth Wells',
  'Bury St Edmunds',
  'Cambridge',
  'Canterbury',
  'Cardiff',
  'Carlisle',
  'Chatham',
  'Chelmsford',
  'Cheltenham',
  'Chester',
  'Chesterfield',
  'Chichester',
  'Clydebank',
  'Colchester',
  'Corby',
  'Coventry',
  'Crawley',
  'Derby',
  'Doncaster',
  'Dudley',
  'Dumfries',
  'Dundee',
  'Eastbourne',
  'Edinburgh',
  'Elgin',
  'Exeter',
  'Fareham',
  'Fort William',
  'Frome',
  'Gairloch',
  'Galashiels',
  'Glasgow',
  'Gloucester',
  'Grantham',
  'Greenock',
  'Grimsby',
  'Guildford',
  'Harlow',
  'Harrogate',
  'Hastings',
  'Haverfordwest',
  'Helmsdale',
  'Hereford',
  'Huddersfield',
  'Hull',
  'Huntly',
  'Inverness',
  'Ipswich',
  'Isle of Arran',
  'Isle of Barra',
  'Isle of Benbecula',
  'Isle of Islay',
  'Isle of Mull Mull',
  'Isle of Scilly',
  'Isle of Tiree',
  'Kendal',
  'Kings Lynn',
  'Kirkwall',
  'Kyle of Lochalsh',
  'Leeds',
  'Leicester',
  'Lincoln',
  'Liverpool',
  'Londonderry',
  'London West',
  'London East',
  'London South',
  'London Central',
  'Lowestoft',
  'Luton',
  'Manchester',
  'Mansfield',
  'Merthyr Tydfil',
  'Middlesbrough',
  'Milton Keynes',
  'Morpeth',
  'Newcastle',
  'Newport Gwent',
  'Newport-Isle Wight',
  'Newry',
  'Northallerton',
  'Northampton',
  'Norwich',
  'Nottingham',
  'Oban',
  'Oldham',
  'Omagh',
  'Oxford',
  'Penzance',
  'Peterborough',
  'Pitlochry',
  'Plymouth',
  'Portadown',
  'Portree',
  'Portsmouth',
  'Preston',
  'Reading',
  'Redditch',
  'Rhyl',
  'Salford',
  'Salisbury',
  'Scarborough',
  'Scunthorpe',
  'Sheffield',
  'Shrewsbury',
  'Skipton',
  'Slough',
  'Southampton',
  'Southend on Sea',
  'Southport',
  'St Helens',
  'Stevenage',
  'Stirling',
  'Stockport',
  'Stoke-on-Trent',
  'Stornoway',
  'Stranraer',
  'Stratford upon Avon',
  'Sunderland',
  'Sutton Coldfield',
  'Swansea',
  'Swindon',
  'Tarbert',
  'Taunton',
  'Tongue',
  'Torquay',
  'Truro',
  'Tunbridge Wells',
  'Ullapool',
  'Watford',
  'Weymouth',
  'Wick',
  'Wigan',
  'Wolverhampton',
  'Worcester',
  'Worthing',
  'Yeovil',
  'York'];

const HOLIDAYS = [
  '2019-12-25',
  '2019-12-26',
  '2020-01-01',
  '2020-04-10',
  '2020-04-13',
  '2020-05-08',
  '2020-05-25',
  '2020-08-31',
  '2020-12-25',
  '2020-12-26',
  '2020-12-28',
];

@Component({
  selector: 'app-hse-test-generator',
  templateUrl: './hse-test-generator.component.html',
  styleUrls: ['./hse-test-generator.component.css']
})
export class HseTestGeneratorComponent implements OnInit {

  numberOfMonths = 1;
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Courses',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  constructor(public datepipe: DatePipe) {
  }

  transform(value: string): string {
    let suffix = 'th';
    const day = value;

    if (day === '1' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '2' || day === '22') {
      suffix = 'nd';
    } else if (day === '3' || day === '23') {
      suffix = 'rd';
    }

    return value + suffix;
  }

  saveCsv() {
    const d = moment().startOf('day').add('3', 'days');
    const end = moment().startOf('day').add(this.numberOfMonths, 'months');
    const data = [];

    const extraColumns = ['ID',
      'Type',
      'SKU',
      'Name',
      'Published',
      'Is featured?',
      'Visibility in catalogue',
      'Short description',
      'Description',
      'Date sale price starts',
      'Date sale price ends',
      'Tax status',
      'Tax class',
      'In stock?',
      'Stock',
      'Low stock amount',
      'Backorders allowed?',
      'Sold individually?',
      'Weight (kg)',
      'Length (cm)',
      'Width (cm)',
      'Height (cm)',
      'Allow customer reviews?',
      'Purchase note',
      'Sale price',
      'Regular price',
      'Categories',
      'Tags',
      'Shipping class',
      'Images',
      'Download limit',
      'Download expiry days',
      'Parent',
      'Grouped products',
      'Upsells',
      'Cross-sells',
      'External URL',
      'Button text',
      'Position',
      'Attribute 1 name',
      'Attribute 1 value(s)',
      'Attribute 1 visible',
      'Attribute 1 global',
      'Attribute 2 name',
      'Attribute 2 value(s)',
      'Attribute 2 visible',
      'Attribute 2 global',
      'Meta: _yst_prominent_words_version',
      'Meta: _wp_page_template', 'Meta: _wcopc',
      'Meta: course_title',
      'Meta: _course_title',
      'Meta: date',
      'Meta: _date',
      'Meta: provider',
      'Meta: _provider',
      'Meta: weekend',
      'Meta: _weekend',
      'Meta: _yoast_wpseo_primary_product_cat',
      'Meta: _yoast_wpseo_focuskeywords',
      'Meta: _yoast_wpseo_keywordsynonyms',
      'Meta: _wapo_disable_global',
      'Meta: _ywtm_hide_additional_information',
      'Meta: _ywtm_override_additional_information',
      'Meta: _ywtm_priority_tab_additional_information',
      'Meta: _ywtm_title_tab_additional_information'];
    const extra = {};
    extraColumns.forEach(column => extra[column] = '');

    while (d.toDate() < end.toDate()) {
      if (d.isoWeekday() !== 6 && d.isoWeekday() !== 7 && !HOLIDAYS.includes(d.format('YYYY-MM-DD'))) {
        // console.log(d.format('YYYY-MM-DD'));
        CITY_OPTIONS.forEach(city => {
          data.push({
            ...extra,

            Name: 'CSCS Health Safety Test ' + this.transform(this.datepipe.transform(d.toDate(), 'd'))
              + this.datepipe.transform(d.toDate(), ' MMMM yyyy') + ' (' + city + ')',

            tags: city,
            Published: '1',
            Type: 'external',
            'Visibility in catalogue': 'visible',
            'In stock?': '1',
            'Regular price': '49',
            Categories: 'cscstest',
            'Allow customer reviews?': '0',
            Images: 'https://www.constructionsiteskills.co.uk/new/wp-content/uploads/2020/02/2015-cscs-test.jpg',
            'Meta: course_title': 'CSCS Health Safety Test',
            'Meta: _wcopc': 'no',
            'Meta: date': this.datepipe.transform(d.toDate(), 'yyyyMMdd'),
            'Meta: _date': 'field_5e494afa28dcd'
          });
        });
      }
      d.add('1', 'days');
    }
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(data);
  }

  ngOnInit() {
  }

}
