import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HseTestGeneratorComponent} from './hse-test-generator/hse-test-generator.component';
import {CourseGeneratorComponent} from './course-generator/course-generator.component';


const routes: Routes = [
  {path: '', redirectTo: '/course-generator', pathMatch: 'full'},
  {path: 'hse-test-generator', component: HseTestGeneratorComponent},
  {path: 'course-generator', component: CourseGeneratorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
