import {CalendarModule} from 'primeng/calendar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DatePipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import { HseTestGeneratorComponent } from './hse-test-generator/hse-test-generator.component';
import { CourseGeneratorComponent } from './course-generator/course-generator.component';

@NgModule({
  declarations: [
    AppComponent,
    HseTestGeneratorComponent,
    CourseGeneratorComponent
  ],
  imports: [
    CalendarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
