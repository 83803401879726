import {Component, OnInit} from '@angular/core';
import {ExportToCsv} from 'export-to-csv';
import {DatePipe} from '@angular/common';

const CITY_OPTIONS = [
  'Aberdeen',
  'Aberystwyth',
  'Aldershot',
  'Aylesbury',
  'Ayr',
  'Ballymena',
  'Bangor',
  'Barnstapl',
  'Barrow',
  'Basildon',
  'Belfast',
  'Berwick',
  'Birmingham',
  'Blackpool',
  'Bolton',
  'Boston',
  'Bournemouth',
  'Bradford',
  'Bridgend',
  'Brighton',
  'Bristol',
  'Builth Wells',
  'Bury St Edmunds',
  'Cambridge',
  'Canterbury',
  'Cardiff',
  'Carlisle',
  'Chatham',
  'Chelmsford',
  'Cheltenham',
  'Chester',
  'Chesterfield',
  'Chichester',
  'Clydebank',
  'Colchester',
  'Corby',
  'Coventry',
  'Crawley',
  'Derby',
  'Doncaster',
  'Dudley',
  'Dumfries',
  'Dundee',
  'Eastbourne',
  'Edinburgh',
  'Elgin',
  'Exeter',
  'Fareham',
  'Fort William',
  'Frome',
  'Gairloch',
  'Galashiels',
  'Glasgow',
  'Gloucester',
  'Grantham',
  'Greenock',
  'Grimsby',
  'Guildford',
  'Harlow',
  'Harrogate',
  'Hastings',
  'Haverfordwest',
  'Helmsdale',
  'Hereford',
  'Huddersfield',
  'Hull',
  'Huntly',
  'Inverness',
  'Ipswich',
  'Isle of Arran',
  'Isle of Barra',
  'Isle of Benbecula',
  'Isle of Islay',
  'Isle of Mull Mull',
  'Isle of Scilly',
  'Isle of Tiree',
  'Kendal',
  'Kings Lynn',
  'Kirkwall',
  'Kyle of Lochalsh',
  'Leeds',
  'Leicester',
  'Lincoln',
  'Liverpool',
  'Londonderry',
  'London West',
  'London East',
  'London South',
  'London Central',
  'Lowestoft',
  'Luton',
  'Manchester',
  'Mansfield',
  'Merthyr Tydfil',
  'Middlesbrough',
  'Milton Keynes',
  'Morpeth',
  'Newcastle',
  'Newport Gwent',
  'Newport-Isle Wight',
  'Newry',
  'Northallerton',
  'Northampton',
  'Norwich',
  'Nottingham',
  'Oban',
  'Oldham',
  'Omagh',
  'Oxford',
  'Penzance',
  'Peterborough',
  'Pitlochry',
  'Plymouth',
  'Portadown',
  'Portree',
  'Portsmouth',
  'Preston',
  'Reading',
  'Redditch',
  'Rhyl',
  'Salford',
  'Salisbury',
  'Scarborough',
  'Scunthorpe',
  'Sheffield',
  'Shrewsbury',
  'Skipton',
  'Slough',
  'Southampton',
  'Southend on Sea',
  'Southport',
  'St Helens',
  'Stevenage',
  'Stirling',
  'Stockport',
  'Stoke-on-Trent',
  'Stornoway',
  'Stranraer',
  'Stratford upon Avon',
  'Sunderland',
  'Sutton Coldfield',
  'Swansea',
  'Swindon',
  'Tarbert',
  'Taunton',
  'Tongue',
  'Torquay',
  'Truro',
  'Tunbridge Wells',
  'Ullapool',
  'Watford',
  'Weymouth',
  'Wick',
  'Wigan',
  'Wolverhampton',
  'Worcester',
  'Worthing',
  'Yeovil',
  'York'];
const COURSE_OPTIONS = ['CITB Health, Safety Awareness (HSA) Course', 'CITB SSSTS Course', 'CITB SMSTS Course'];
const PARTNER_OPTIONS = ['1001', '1002', '1003', '1004', '1005', '1006', '1007', '1008', '1009', '1010'];
const IMAGES_OPTIONS = ['https://www.constructionsiteskills.co.uk/new/wp-content/uploads/2020/02/green-cscs-card-course.jpg', '1002', '1003', '1004', '1005', '1006', '1007', '1008', '1009', '1010'];
const CATEGORIES_OPTIONS = ['hsa', ' sssts', 'smsts'];
const WHATEVER_OPTIONS = ['1', ' 2'];
const WHAT_OPTIONS = ['1', ' 2'];


@Component({
  selector: 'app-course-generator',
  templateUrl: './course-generator.component.html',
  styleUrls: ['./course-generator.component.css']
})
export class CourseGeneratorComponent implements OnInit {
  title = 'courses';
  dates: Date[];
  price: string;
  city: string;
  provider: string;
  images: string;
  categories: string;
  course: string;
  whatever: string;
  what: string;
  data: any[] = [];

  courseOptions = COURSE_OPTIONS;
  cityOptions = CITY_OPTIONS;
  partnerOptions = PARTNER_OPTIONS;
  imagesOptions = IMAGES_OPTIONS;
  categoriesOptions = CATEGORIES_OPTIONS;
  whateverOptions = WHATEVER_OPTIONS;
  whatOptions = WHAT_OPTIONS;


  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Courses',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  constructor(public datepipe: DatePipe) {
  }


  transform(value: string): string {
    let suffix = 'th';
    const day = value;

    if (day === '1' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '2' || day === '22') {
      suffix = 'nd';
    } else if (day === '3' || day === '23') {
      suffix = 'rd';
    }

    return value + suffix;
  }

  add() {
    if (!this.dates || this.dates.length === 0) {
      return;
    }
    const extraColumns = [
        'ID',
        'Type', 
        'SKU', 
        'Name', 
        'Published', 
        'Is featured?', 
        'Visibility in catalogue', 
        'Short description',
      'Description', 
      'Date sale price starts', 
      'Date sale price ends', 
      'Tax status', 
      'Tax class', 
      'In stock?', 
      'Stock', 
      'Low stock amount',
      'Backorders allowed?', 
      'Sold individually?', 
      'Weight (kg)', 
      'Length (cm)', 
      'Width (cm)', 
      'Height (cm)', 
      'Allow customer reviews?',
      'Purchase note', 
      'Sale price', 
      'Regular price', 
      'Categories', 
      'Tags', 
      'Shipping class', 
      'Images', 
      'Download limit',
      'Download expiry days', 
      'Parent', 
      'Grouped products', 
      'Upsells', 
      'Cross-sells', 
      'External URL', 
      'Button text', 
      'Position',
      'Attribute 1 name', 
      'Attribute 1 value(s)', 
      'Attribute 1 visible', 
      'Attribute 1 global', 
      'Attribute 2 name', 
      'Attribute 2 value(s)',
      'Attribute 2 visible', 
      'Attribute 2 global', 
      'Meta: _yst_prominent_words_version', 
      'Meta: _wp_page_template', 
      'Meta: _wcopc',
      'Meta: course_title', 
      'Meta: _course_title', 
      'Meta: date', 
      'Meta: _date', 
      'Meta: provider', 
      'Meta: _provider', 
      'Meta: weekend',
      'Meta: _weekend', 
      'Meta: _yoast_wpseo_primary_product_cat', 
      'Meta: _yoast_wpseo_focuskeywords', 
      'Meta: _yoast_wpseo_keywordsynonyms',
      'Meta: _wapo_disable_global', 
      'Meta: _ywtm_hide_additional_information', 
      'Meta: _ywtm_override_additional_information',
      'Meta: _ywtm_priority_tab_additional_information', 
      'Meta: _ywtm_title_tab_additional_information'];
    const extra = {};
    extraColumns.forEach(column => extra[column] = '');
    this.dates.forEach(date => {
      this.data.push({
        ...extra,
        'Meta: provider': this.provider,
       // city: this.city,
        // provider: this.provider,
        'Type' : 'external',
        'Name' : this.course + ' ' + this.transform(this.datepipe.transform(date, 'd')) + this.datepipe.transform(date, ' MMMM yyyy') + ' (' + this.city + ')',
         'Published' : '1',
        'Visibility in catalogue' : 'visible',
          'In stock?' : '1',
        'Regular price' : this.price,
        'Categories' : this.categories,
        'Allow customer reviews?' : '0',
        'Images' : this.images,
        'Attribute 1 name' : 'Location',
        'Attribute 1 global' : '1',
        'Attribute 1 value(s)' : this.city,
        'Attribute 2 name' : 'Month',
        'Attribute 2 global' : '1',
        'Attribute 2 value(s)' : this.datepipe.transform(date, 'MMMM'),
        'Meta: course_title' : this.course,
        'Meta: _wcopc' : 'no',
        'Meta: date' : this.datepipe.transform(date, 'yyyyMMdd'),
        'Meta: _date' : 'field_5e494afa28dcd', 
     //   price: this.price,
        //date: this.datepipe.transform(date, 'yyyyMMdd'),
       // whatever: this.whatever,
      //  what: this.what,
       // month: this.datepipe.transform(date, 'MMMM')
      });
    });
    this.city = '';
    this.provider = '';
    this.dates = [];
    this.price = '';
    this.images = '';
    this.categories = '';
    this.course = '';
    this.whatever = '';
    this.what = '';
  }

  saveCsv() {
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(this.data);
  }

  ngOnInit() {
  }
}
